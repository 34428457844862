import { template as template_ab35d059e2c84d938fb796c7e1bd6ca2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ab35d059e2c84d938fb796c7e1bd6ca2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
